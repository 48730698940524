.Example {
    --background-color-1: #202228;
    --background-color-2: #292c33;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: 1px solid #3a3a3a;
    background: repeating-linear-gradient(45deg,
            var(--background-color-1),
            var(--background-color-1) 10px,
            var(--background-color-2) 10px,
            var(--background-color-2) 20px);
}

.Example > .Example-inner {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 600px;
    background: white;
}